:root {
  --color-primary: #4D81F1;
  --color-primary-hover: #4879e0;
  --color-text: #1e1f21;
  --color-text-lighter: #4C4D4E;
  --color-body-background: #F9F8F8;
  --color-main-background: #fff;
  --color-gray: #eaeaea;
  --color-darker-gray: #d5d5d5;
  --color-success: #3ecf3e;
}

.dark-mode {
  --color-text: white;
  --color-text-lighter: #a5b0b9;
  --color-body-background: #13151c;
  --color-main-background: #1c1f28;
}

body {
  background-color: var(--color-body-background) !important;
  margin: 0;
  padding: 0;
  color: var(--color-text) !important;
  font-family: 'Inter', sans-serif !important;
  font-weight: 400;
  font-style: normal;
}

.nav-link {
  color: var(--color-text) !important;
}

.main-content-wrapper {
  padding-top: 82px;
}

.navbar {
  z-index: 9;
  background-color: var(--color-main-background);
}

.navbar .nav-link {
  font-size: 13pt;
  padding: 20px !important;
  color: var(--color-text-lighter) !important;
}

.navbar .nav-link.active,
.nav-link:hover {
  color: var(--color-text) !important;
}

#logo-image {
  max-height: 45px;
  height: 100%;
}

.logo-image-wrapper {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.bold {
  font-weight: bold !important;
}

.btn-primary {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}

.btn-primary:hover {
  background-color: var(--color-primary-hover) !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.txt-main {
  color: var(--color-text) !important;
}

.txt-secondary {
  color: rgb(101, 105, 110) !important;
}

.rounded-large {
  border-radius: 1.5rem !important;
}

.txt-light {
  color: var(--color-text-lighter) !important;
}

.txt-success {
  color: var(--color-success);
}

.tech-stack-list li {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-weight: 500;
}

.experience-level {
  display: flex;
  align-items: center;
  padding-top: .5rem;
}

.experience-level .line {
  height: 8px;
  width: 35px;
  margin-right: .5rem;
  border-radius: 1.3rem;
  background-color: var(--color-primary);
}

@media only screen and (max-width: 850px) {
  h2 {
    text-align: center;
  }

  .navbar-nav {
    background-color: #101418 !important;
  }
}


::-webkit-scrollbar {
  width: 10px
}

::-webkit-scrollbar-track {
  background-color: transparent
}

::-webkit-scrollbar-thumb {
  background-color: #a1a1a1;
  border-radius: 2rem
}

.checkbox {
  opacity: 0;
  position: absolute;
}

.label {
  background-color: var(--color-body-background);
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  position: relative;
  height: 26px;
  width: 50px;
  transform: scale(1.5);
}

.label .ball {
  background-color: var(--color-darker-gray);
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  height: 22px;
  width: 22px;
  transform: translateX(0px);
  transition: transform 0.2s linear;
}

.checkbox:checked+.label .ball {
  transform: translateX(24px);
}


.moon {
  color: var(--color-primary) !important;
}

.sun {
  color: #fff022 !important;
}

.card {
  background-color: var(--color-main-background) !important;
  border: none !important;
  color: var(--color-text) !important;
}

.text-justify {
  text-align: justify;
}

.company-logo {
  width: 65px;
}


@keyframes opacity-animation {
	0% {
		opacity: 0
	}
}

@keyframes pulse-animation {
	0% {
		transform: translate(-50%, -50%) scale(0);
		opacity: .8
	}
	70% {
		opacity: 0;
		transform: translate(-50%, -50%) scale(3)
	}
	to {
		transform: translate(-50%, -50%) scale(0);
		opacity: 0
	}
}

.pulse:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background: #f9e9a2;
    border-radius: 100%;
    opacity: .5;
    transform: translate(-50%,-50%) scale(3);
    animation: pulse-animation 1.5s infinite;
}

.pulse {
    position: relative;
    display: inline-block;
    vertical-align: 1px;
    width: 12px;
    height: 12px;
    margin: 0 6px;
    background: #ffdd40;
    color: transparent;
    border-radius: 100%;
    flex: 0 0 auto;
    animation: opacity-animation .3s linear;
  &.red {
    background: #e60000;
  }
  &.orange {
    background: #ff9900;
  }
  &.green {
    background: #00e600;
  }
}

.bgc-success {
  background-color: var(--color-success) !important;
}

hr {
  border-color: var(--color-darker-gray) !important;
}

.form-control {
  padding: .7rem .6rem !important;
}

.dropdown-toggle::after {
  display: none !important;
}


[data-rmiz-content="found"] img,
[data-rmiz-content="found"] svg,
[data-rmiz-content="found"] [role="img"],
[data-rmiz-content="found"] [data-zoom] {
  cursor: pointer !important;
}

.image {
  width: 100%;
  height: auto;
  cursor: pointer !important;
  transition: transform 0.3s ease;
}

.image:hover {
  transform: scale(1.04);
}