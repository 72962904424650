@media only screen and (min-width: 850px) {
    .homeMainWrapper {
        position: relative;
        overflow: hidden;
    }

    .homeMainWrapper::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('../../assets/img/background.svg');
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        transform: rotate(180deg);
        z-index: -1;
    }
}

.homeMainWrapper{
    padding: 0 0 5rem 0;
}

@media only screen and (max-width: 850px) {
    #my-picture {
        max-width: 150px !important;
    }
}

.company-logo {
    max-width: 85px;
    width: 100%;
    height: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
}